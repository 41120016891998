import { LOCALE } from '../i18n';
import { default as localConfig } from './local.config';
import { default as devConfig } from './dev.config';
import { default as prodConfig } from './prod.config';

export interface Config {
  graph: string;
  firebase: FirebaseConfig;
  sentry: SentryConfig;
  aws: AWSConfig;
  ga4ID?: string;
  domains: DomainsConfig;
  kidSiteDomain: KidSiteDomainConfig;
}

export type DomainsConfig = {
  [locale in LOCALE]: {
    base: string;
    pathname: string;
  };
};

export interface KidSiteDomainConfig {
  base: string;
}

/**
 * Obtained from firebase > project > add firebase to your webapp
 */
export interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
}

/**
 * Obtained from sentry > projects > ... > client keys
 */
export interface SentryConfig {
  dsn: string;
  disabled?: boolean;
}

export interface AWSConfig {
  imagesCdnPrefix: string;
  videoCdnPrefix: string;
  filesDomain: string;
}

function getConfig() {
  const configFilePrefix = process.env.REACT_APP_CONFIG || 'local';
  if (configFilePrefix === 'dev') {
    return devConfig;
  } else if (configFilePrefix === 'prod') {
    return prodConfig;
  } else {
    return localConfig;
  }
}

const config = getConfig();
export { config };

export const appName = 'crazygames-devportal';
export const appVersion = process.env.REACT_APP_VERSION || '0.0.0';

export default config;
