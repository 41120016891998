import React from 'react';
import { Dialog, DialogActions, DialogContent, Alert } from '@mui/material';
import { StyledBodyText, StyledHeaderTextH2 } from '../../../../../common/Styleguide/Common/Text';
import { StyledButton } from '../../../../../common/Styleguide/Common/Button';
import { toast } from 'react-toastify';
import logo from '../../../../../common/img/game-jam-logo.svg';
import { GAMEJAM_LINK, GAMEJAM_LINK_DISPLAY, GameJamLink, getGameJamLink } from './gamejamHelper';
import ArrowLeft from '../../../../../common/icons/ArrowLeft';
import { ContentCopy, OpenInNew } from '@mui/icons-material';

interface GameJamConfirmationProps {
  submissionId: string;
  goToSubmission: () => void;
}

const GameJamConfirmation: React.FC<GameJamConfirmationProps> = ({ submissionId, goToSubmission }) => {
  const link = getGameJamLink(submissionId);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link);
    toast.success('Link copied to clipboard');
  };

  return (
    <Dialog open maxWidth={'md'}>
      <DialogContent
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={logo} style={{ height: 120 }} alt="Game Jam" />
          </div>
          <StyledHeaderTextH2>Thank You for Your Submission!</StyledHeaderTextH2>
          <StyledBodyText>
            Here is your shareable link:{' '}
            <GameJamLink href={link} target="_blank">
              {link}
            </GameJamLink>
          </StyledBodyText>
          <Alert severity="warning" sx={{ fontSize: 18 }}>
            Please make sure to test the game using the link provided above.
          </Alert>
          <StyledBodyText>
            For now, your game is only accessible as a preview via the link. It cannot be discovered on CrazyGames and does not generate any
            revenue at this stage.
          </StyledBodyText>
          <StyledBodyText>
            You can modify your submission at any moment until the game jam ends. Updates can take a few minutes to be visible on the
            preview page.
          </StyledBodyText>
          <Alert severity="warning" sx={{ fontSize: 18 }}>
            Sending your game to Crazygames is not enough to participate in the game jam. Please make sure to also register your
            participation at{' '}
            <GameJamLink href={GAMEJAM_LINK} target="_blank" sx={{ color: 'white' }}>
              {GAMEJAM_LINK_DISPLAY}
            </GameJamLink>{' '}
            using the shareable link provided above.
          </Alert>
          <StyledBodyText>
            Participation details:
            <ul>
              <li>The preview link stays the same even after updating your game and you can make updates until the game jam ends.</li>
              <li>
                It is definitely recommended to register your participation on{' '}
                <GameJamLink href={GAMEJAM_LINK} target="_blank" sx={{ color: 'white' }}>
                  {GAMEJAM_LINK_DISPLAY}
                </GameJamLink>{' '}
                well before the game jam ends.
              </li>
              <li>You won't be able to update your game nor register your participation after the game jam ends.</li>
              <li>The deadline (11:59PM CET on May 5) is handled automatically by our system and no exception can be made.</li>
            </ul>
          </StyledBodyText>
          <StyledBodyText>
            Note: Your game will not be published on CrazyGames immediately. However, our team is always on the lookout for great games. We
            will be reviewing all game jam submissions, and if we determine that your game has commercial potential, we will reach out with
            further instructions in the coming weeks.
          </StyledBodyText>
        </div>
      </DialogContent>
      <DialogActions sx={{ py: 3 }}>
        <StyledButton onClick={goToSubmission} variant="contained" color="white" height={50} sx={{ minWidth: 200, mr: 1 }}>
          <ArrowLeft />
          Back to submission
        </StyledButton>
        <StyledButton onClick={handleCopyLink} variant="contained" color="white" height={50} sx={{ minWidth: 200 }}>
          <ContentCopy />
          Copy link
        </StyledButton>
        <a href={link} target="_blank" rel="noreferrer">
          <StyledButton variant="contained" color="purple" height={50} sx={{ minWidth: 200 }}>
            <OpenInNew />
            Preview your game
          </StyledButton>
        </a>
      </DialogActions>
    </Dialog>
  );
};

export default GameJamConfirmation;
