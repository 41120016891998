import config from '../config';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { setContext } from '@apollo/client/link/context';
import {
  ApolloClient,
  InMemoryCache,
  MutationOptions,
  FetchResult,
  TypedDocumentNode,
  MutationHookOptions,
  MutationResult,
  createHttpLink,
  ApolloLink,
} from '@apollo/client';
import { isLocalHost } from '../url';

import { sha256 } from 'crypto-hash';

const headers: { [key: string]: string } = {};

export function setHeader(key: string, value?: string) {
  if (value) {
    headers[key] = value;
  } else {
    delete headers[key];
  }
}

const persistedQueriesLink = createPersistedQueryLink({ sha256, useGETForHashedQueries: true });

const httpLink = createHttpLink({
  uri: config.graph,
});

const authLink = setContext((_, prevContext) => {
  return {
    headers: {
      ...prevContext.headers,
      ...headers,
    },
  };
});

// Avoids using persisted queries on localhost, as they are much less readable
const link = ApolloLink.from(isLocalHost(window.location.href) ? [authLink, httpLink] : [persistedQueriesLink, authLink, httpLink]);

export const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache({
    typePolicies: {
      UploadedFile: {
        keyFields: ['uploadId'],
      },
    },
  }),
});

export async function mutate<I, R = any>(
  mutation: TypedDocumentNode<R, GraphInputType<I>>,
  input: I,
  options: Omit<MutationOptions<R, GraphInputType<I>>, 'mutation' | 'variables'> = {},
): Promise<FetchResult<R>> {
  return apolloClient.mutate<R, GraphInputType<I>>({
    mutation: mutation,
    variables: {
      input: input,
    },
    ...options,
  });
}

// Its a best practice to name your input just input, so thats what we'll do
export type GraphInputType<I> = { input: I };
export function makeHook<I, R = any>(
  fn: (options?: MutationHookOptions<R, { input: I }>) => [(input: I) => Promise<FetchResult<{ input: I }>>, MutationResult<R>],
) {
  return fn;
}
